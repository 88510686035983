import React from "react";

function Card(props) {
  return (
    <div className="w-full md:w-1/3 my-5 cardcontent md:w-full">
      <div className="flex flex-col md:flex-wrap items-center max-w-[250px] w-full mx-auto ">
        <img src={props.data.path} alt={props.data.icon} width={"30px"}></img>
        <h1 className="my-2">{props.data.title}</h1>
        <p className=" text-[15px]">{props.data.text}</p>
      </div>
    </div>
  );
}

export default Card;
