import React from "react";

function Footer() {
  return (
    <div className="border-t-2 border-[#d9b865]">
      <div className="flex justify-around flex-wrap  mx-auto py-[50px]">
        
      <div className="cursor-pointer  max-w-[150px] ">
      <a href="/#">
            <img
              src={process.env.PUBLIC_URL + "assets/images/logo.png"}
              width="100%"
            ></img>
            </a>
          </div>
        <div className="flex  justify-center gap-6 text-left pt-[20px] md:pt-[0]">
          <div className="flex  gap-4 text-[18px]">
          <a href="/#">
            <span className="">Home</span>
            </a>
            <a href="#tokenomics">
            <span>Tokenomics</span>
            </a>
            <span>
              <a href="#roadmap">
              Roadmap
              </a>
              </span>
            {/* <span>Team</span> */}
          </div>
          
        </div>
        <div className="flex justify-center  items-center pt-[20px] md:pt-[0] cursor-pointer">
          <div className="flex gap-4 mb-[25px]">
            {SocialLinksData.map((item, key) => (
              <img
                key={key}
                width="30px"
                alt={item.name.toUpperCase()}
                src={item.path}
              ></img>
            ))}
          </div>
        </div>
        <div>
          <div className="pt-[20px] md:pt-[0]">
          <h4>
© 2022 Project Psalms. All rights reserved</h4>
</div>
        </div>
      </div>
      {/* <div className="flex justify-center  items-center">
          <div className="flex gap-4 mb-[25px]">
            {SocialLinksData.map((item, key) => (
              <img
                key={key}
                width="30px"
                alt={item.name.toUpperCase()}
                src={item.path}
              ></img>
            ))}
          </div>
        </div> */}
    </div>
  );
}

const SocialLinksData = [
  {
    link: "/",
    name: "Discord",
    path: process.env.PUBLIC_URL + "assets/images/discord.svg",
  },
  {
    link: "/",
    name: "Twitter",
    path: process.env.PUBLIC_URL + "assets/images/twitter.svg",
  },
  {
    link: "/",
    name: "Telegram",
    path: process.env.PUBLIC_URL + "assets/images/telegram.svg",
  },
  {
    link: "/",
    name: "Facebook",
    path: process.env.PUBLIC_URL + "assets/images/facebook.svg",
  },
];

export default Footer;
