import "./App.css";
import React, { useEffect } from "react";
import HomePage from "./pages/HomePage";
import { isWalleConnect } from "./Redux/Action";
import { ConnectMetamask, DisconnectWallet } from "./Services";
import { useWeb3React } from "@web3-react/core";
import Swal from "sweetalert2";

function App() {
  const { chainId, deactivate } = useWeb3React();
  console.log(chainId, "yeahhh id");
  useEffect(() => {
    if (chainId == 5) {
      console.log("yes");
    } else if (chainId === undefined) {
    } else {
      Swal.fire("Please Choose Right Network");
      deactivate();
      console.log("no");
      return;
    }
  }, [chainId]);

  if (
    localStorage.getItem("WalletConnect") !== null &&
    JSON.parse(localStorage.getItem("WalletConnect")).isConnect === true
  ) {
    let data = JSON.parse(localStorage.getItem("WalletConnect"));
    console.log(data);
    isWalleConnect(data.isConnect, data.address);
    ConnectMetamask();
  }
  return (
    <div className="App font-bold ">
      <HomePage />
    </div>
  );
}

export default App;
