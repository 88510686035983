import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import Card from "../Components/Card/Card";
import pdf from "../Files/whtepaper.pdf";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import { ICOTokenABI } from "../Config/ABI/PayoutTokenABI";
import { BUSDTokenABI } from "../Config/ABI/BUSDTokenABI";
import { Link } from "react-router-dom";

import BigNumber from "bignumber.js";
import {
  BUSDToken,
  ICOTokenContract,
  PayoutTokenContract,
} from "../Config/Contract/Contract";
import { web3_ } from "../Services";
import moment from "moment";
import { useWeb3React } from "@web3-react/core";
import { NetworkChanged, _web3 } from "../Services";

function HomePage(props) {
  const [showModal, setShowModal] = useState(false);
  const [isApprove, setisApprove] = useState(false);
  const [number, setNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isConnect, setConnect] = useState(false);
  const [isWeb3, setIsWeb3] = useState(null);
  const [Network, setNetwork] = useState(false);
  const [tokonomics, setTokonomics] = useState(null);
  const [BNBWalletBalance, setBNBWalletBalance] = useState(0);
  const { active, account } = useWeb3React();
  useEffect(async () => {
    setConnect(active);
    console.log(active);
    setTokonomics(await ICOGetTokenomics());
    if (active) {
      new web3_.eth.getBalance(account).then((res) => setBNBWalletBalance(res));
    }
  }, [active, account]);

  useEffect(() => {
    let res = NetworkChanged();
    setNetwork(res);
  }, []);

  console.log(tokonomics === null ? null : tokonomics[2]);
  // gettokonomics
  const ICOGetTokenomics = async () => {
    return await new web3_.eth.Contract(ICOTokenABI, ICOTokenContract).methods
      .getTokenomics()
      .call();
  };

  //getbalance
  const ICOGetBalance = async () => {
    return await new web3_.eth.Contract(BUSDTokenABI, BUSDToken).methods
      .balanceOf(ICOTokenContract)
      .call();
  };

  const SalesData = [
    {
      title: "TOKEN NAME",
      value: "Project Psalms Token",
    },
    {
      title: "TOKEN SYMBOL",
      value: "PPT",
    },
    {
      title: "TOKEN TYPE",
      value: "BEP20 (BSC)",
    },
    {
      title: "TOTAL TOKEN SUPPLY",
      value: "100,000,000",
    },
    {
      title: "PRIVATE SALE ALLOCATION",
      value: "20,000,000",
    },
    {
      title: "PRIVATE SALE TOKEN PRICE 1 PTT ",
      value:
        tokonomics !== null
          ? tokonomics[1] / Math.pow(10, 18) + " BNB"
          : "0 BNB",
    },
    {
      title: "START TIME",
      value:
        tokonomics !== null
          ? moment(tokonomics[2] * 1000).format("LL")
          : "START TIME IS NOT SET",
    },
    {
      title: "END TIME",
      value:
        tokonomics !== null
          ? moment(tokonomics[3] * 1000).format("LL")
          : "END TIME IS NOT SET",
    },
    {
      title: "COMPANY EXPENSES",
      value: "10,000,000",
    },

    {
      title: "PUBLIC SALE ALLOCATION",
      value: "50,000,000",
    },
    {
      title: "TREASURY",
      value: "20,000,000",
    },
  ];
  // close gettokonomics
  async function Buy() {
    // alert("hello world");
    let data = await ICOGetTokenomics(ICOTokenContract);
    let balanceToken =
      (await ICOGetBalance(ICOTokenContract)) / Math.pow(10, 16);
    console.log(balanceToken, "balance of pslams");

    console.log(data[1] / Math.pow(10, 18), "Presale rate");
    console.log(data[2], new Date().getTime() / 1000);
    console.log(data[3], new Date().getTime() / 1000);
    // console.log(parseFloat(number),"number of Psalms");

    const IDOContract = new web3_.eth.Contract(ICOTokenABI, ICOTokenContract);

    if (active) {
      let chainID = await web3_.eth.getChainId();
      console.log(chainID, "ids");
      if (chainID === 5) {
        if (number !== "") {
          console.log(
            IDOContract,
            new BigNumber(Math.pow(10, 18) * number).toString()
          );
          let Number = web3_.utils.toWei(number, "ether");

          console.log(Number, "this the number");
          let NumberOfTokens = parseInt(Number) / data[1];
          console.log(NumberOfTokens, "userdata");

          if (parseFloat(number) > 0) {
            if (new Date().getTime() / 1000 >= data[2]) {
              if (new Date().getTime() / 1000 < data[3]) {
                if (NumberOfTokens <= balanceToken) {
                  if (
                    parseFloat(number) <=
                    BNBWalletBalance / Math.pow(10, 18)
                  ) {
                    BuyToken(NumberOfTokens);
                  } else {
                    Swal.fire(
                      "Warning!",
                      "You dont have enough BNB amount",
                      "warning"
                    );
                  }
                } else {
                  Swal.fire(
                    "Warning!",
                    "ICO don't have sufficient funds.",
                    "warning"
                  );
                }
              } else {
                Swal.fire("Warning!", "Sale is Ended", "warning");
              }
            } else {
              Swal.fire("Warning!", "Sale is not started yet", "warning");
            }
          } else {
            Swal.fire("Warning!", "Please enter valid amount", "warning");
          }
        } else {
          Swal.fire("Warning!", "Please add amount", "warning");
        }
      } else {
        Swal.fire("Please Choose Right Network");
      }
    } else {
      Swal.fire("Warning!", "Please connect to the Wallet", "warning");
    }

    async function BuyToken(NumberOfTokens) {
      setIsLoading(true);
      await IDOContract.methods
        .SaleICOToken()
        .send({
          from: account,
          value: web3_.utils.toWei(number, "ether"),
          gasPrice: "20000000000",
        })
        .on("transactionHash", function (transactionHash) {
          console.log(transactionHash);
        })
        .on("confirmation", () => {})
        // get New Contract Address
        .then((res) => {
          setIsLoading(false);
          // setisApprove(false);
          setShowModal(false);
          setNumber("");
          Swal.fire(
            "Transaction Successful",
            "You got " + NumberOfTokens + " PTT on wallet.",
            "success"
          );
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);

          Swal.fire("warning", "User denied transaction ", "error");
        });
    }
  }
  return (
    <>
      <Header />
      {/* Section 1 start  */}
      <div>
        <section className="flex justify-center w-full max-w-[1300px] mx-auto px-[30px] md:px-[80px] md:py-[80px] py-[30px]">
          <div className="flex flex-col md:flex-row gap-3 items-center pt-[50px]">
            <div className="text-left pb-[50px] pt-[20px] flex-1">
              <div className="mb-[20px]">
                <div className="mb-[10px]">
                  <h1 className="text-4xl">The Governance Token of BEEPBOP</h1>
                  <h3 className="text-xl">
                    A Post-to-Earn and Social Media Application.
                  </h3>
                </div>
                <p className="text-[18px] font-[300] ">
                  BEEPBOP is a Post-to-Earn Social Media Application. Users will
                  earn tokens from Views, Likes and Shares. BEEPBOP has a
                  integrated wallet, exchange platform and marketplace
                </p>
              </div>
              <div className="flex flex-wrap flex-col md:flex-row  gap-4 items-center font-semibold">
                <div className="max-w-[250px] w-full text-center border rounded-full  px-[32px] py-[16px] text-[16px] bg-gradient-to-r from-[#f4e9b0] to-[#cca143] text-black cursor-pointer ">
                  <a href={pdf} target="blank">
                    WHITEPAPER
                  </a>
                </div>
                <div
                  onClick={() => {
                    setShowModal(true);
                    setIsLoading(false);
                    setisApprove(false);
                    setNumber("");
                  }}
                  className="border-2 max-w-[250px] w-full text-center border-[#d9b865] rounded-full  px-[32px] py-[16px] text-[16px] cursor-pointer "
                >
                  BUY TOKEN NOW
                </div>
              </div>
            </div>
            <div className="flex-1">
              <img
                width="100%"
                src={process.env.PUBLIC_URL + "assets/images/hero-01.svg"}
                alt="right-hero-img"
              ></img>
            </div>
          </div>
        </section>

        <div className="text-2xl  flex justify-center my-[10px] px-[30px] md:px-[80px] ">
          <h1 className="tokendescp md:w-[35%] w-[100%] ">
            Project Psalms Token (PPT) is the native governance token of BEEPBOP
            and has the following use cases.
          </h1>
        </div>
        {/* Section 2 Start   */}
        <section className="flex  justify-center w-full max-w-[1300px] mx-auto px-[30px] md:px-[80px] ">
          <div className="flex flex-col md:flex-row flex-row-reverse gap-3 items-center py-[50px]">
            <div className="text-left py-[80px] flex-1 ">
              {Section2Data.map((item, key) => (
                <div className="mx-5 my-5">
                  <div className="text-2xl">{item.title}</div>
                  <p className="text-[18px]  font-semibold">{item.text}</p>
                </div>
              ))}
            </div>
            <div className="flex-1">
              <img
                width="100%"
                src={process.env.PUBLIC_URL + "assets/images/sec-02.svg"}
                alt="right-hero-img"
              ></img>
            </div>
          </div>
        </section>
        <div className="text-2xl  flex justify-center my-[10px] px-[30px] md:px-[80px]">
          <h1 className="currentfeatures md:w-[35%] w-[100%] md:w-full">
            Current feature of BEEPBOP includes
          </h1>
        </div>

        <section className="px-[30px] md:px-[80px]">
          <div className="grid grid-rows-1 justify-center  pt-[30px]">
            <div className="flex flex-wrap justify-center max-w-[1300px] text-[18px] gap-4">
              {FeatureData.map((item, key) => (
                <Card data={item} key={key} />
              ))}
            </div>
          </div>
        </section>

        <section className="flex w-full  py-[30px] max-w-[1300px] mx-auto relative px-[30px] md:px-[80px]">
          <img
            src={process.env.PUBLIC_URL + "assets/images/Lines.svg"}
            width="100%"
            className="absolute top-[60%] md:top-[unset] bottom-0"
          ></img>
          <div className="max-w-2xl flex-col items-center mx-auto ">
            <h1 className="text-2xl my-5 text-center">What is BEEPBOP?</h1>

            <p className="">
              BEEPBOP is a social media application, users will earn prosperity
              token thru posting photo and video content. BEEPBOP account can be
              upgraded to increase the number of followers/ audience. BEEPBOP
              has a dual-token system, in app Prosperity Token (PSTT) and a
              governance token Project Psalms Token (PPT), users can stake PPT
              to earn rewards. The project aims to inspire millions of people to
              be creative. BEEPBOP uses token rewards to promote a new way of
              earning thru social media.
            </p>
          </div>
        </section>

        {/* Project Highlights  */}
        <section className="my-10 flex relative max-w-[1300px] mx-auto px-[30px] md:px-[80px]">
          <img
            src={process.env.PUBLIC_URL + "assets/images/Lines.svg"}
            width="100%"
            className="absolute  bottom-0"
          ></img>
          <div className=" flex flex-col md:flex-row flex items-center w-full gap-20">
            <div className="text-left flex-1 md:w-48 ">
              <h1 className="text-left text-xl ">Project Key Highlights</h1>
              <div className="flex-1">
                {ProjectHighLight.map((item, key) => (
                  <div key={key} className="my-3">
                    <span className="text-base">{item.title}</span>: &nbsp;
                    <span className="text-[16px]   font-[400]">
                      {item.text}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex-1 mx-auto">
              <img
                className="max-w-[450px] flex-1 flex "
                width="100%"
                src={process.env.PUBLIC_URL + "assets/images/12.png"}
              ></img>
            </div>
          </div>
        </section>

        {/* Products Hightlights  */}
        <section className="py-[50px] px-[30px] md:px-[80px]">
          <div className="md:px-[80px] px-0  flex flex-col md:flex-row gap-7 items-center justify-center max-w-[1300px] mx-auto">
            <div className="text-left flex-1">
              <h1 className="text-left text-xl ">Products</h1>
              <div className="">
                {Products.map((item, key) => (
                  <div key={key} className="my-3">
                    <span className="text-base">{item.title}</span> &nbsp;
                    <span className="text-[18px]   font-semibold">
                      {item.text}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex-1">
              <img
                class="max-w-[450px] flex-1 flex "
                width="100%"
                src={process.env.PUBLIC_URL + "assets/images/Product.svg"}
                // width="100%"
              ></img>
            </div>
          </div>
        </section>

        {/* Products Hightlights  */}
        <section className="py-[50px] px-[30px] md:px-[80px]">
          <div className=" md:px-[80px] px-0 flex flex-col md:flex-row relative gap-20 flex-row-reverse items-center justify-center max-w-[1300px] mx-auto">
            <img
              src={process.env.PUBLIC_URL + "assets/images/Lines.svg"}
              width="100%"
              className="absolute top-0 md:top-[50%] bottom-0"
            ></img>
            <div className="text-left flex-1 text-[18px]" id="tokenomics">
              <h1 className="text-left text-xl ">Token Allocation</h1>
              {/* <p className="text-[17px]">Project Psalms Token</p> */}

              {SalesData.map((item, key) => (
                <div className="flex justify-between text-[10px] md:text-[18px] ">
                  <span className="text-[15px]">{item.title}</span>
                  <span>{item.value}</span>
                </div>
              ))}
            </div>
            <div className="flex-1">
              <img
                src={process.env.PUBLIC_URL + "assets/images/pie.svg"}
                width="100%"
                className="max-w-[470px]"
              ></img>
            </div>
          </div>
        </section>
        <section className="flex justify-center" id="roadmap">
          <div className=" mt-[30px] px-[30px]">
            <h1 className="text-[40px]">Roadmap</h1>
            <img
              className="w-[800px] max-w-[100%]"
              width="100%"
              src={process.env.PUBLIC_URL + "assets/plasm-roadmap.svg"}
              alt="right-hero-img"
            ></img>
          </div>
        </section>

        {/* // News Letter  */}
        <section className="py-[50px]">
          <div className="px-[30px] md:px-[80px]">
            <div>
              <h1 className="my-[20px] text-2xl">Subscribe Our Newsletter</h1>
              <p className="text-[18px] ">
                Service to country isn’t linked to combat, Our though I served
                as an infantry officer in time of war, went
              </p>
            </div>
            <div className="flex justify-center items-center my-[25px]">
              <div className="flex rounded items-center bg-gradient-to-r from-[#f4e9b0] to-[#cca143]">
                <input
                  className="py-2 px-5 my-[2px] ml-[2px] bg-black"
                  placeholder="Email"
                ></input>
                <div className=" px-5 text-white">Subscribe</div>
              </div>
            </div>
          </div>
        </section>

        {showModal ? (
          <>
            <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                <div className="md:w-[30rem] w-full border rounded-lg shadow-lg relative flex flex-col w-full bg-[#000] outline-none focus:outline-none popmodel border-2 border-[#cca143]">
                  <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t bg-gradient-to-r from-[#f4e9b0] to-[#cca143]">
                    <h3 className="text-3xl font-semibold text-black">
                      Buy Token
                    </h3>
                    <button
                      className="bg-transparent border-0 text-black float-right"
                      onClick={() => setShowModal(false)}
                    >
                      <span className="text-black opacity-7 h-6 w-6 text-xl block  py-0 rounded-full">
                        x
                      </span>
                    </button>
                  </div>
                  <div className="relative p-6 flex-auto">
                    <div className=" shadow-md rounded px-8 pt-6 pb-8 w-full">
                      <label className="block text-wh text-sm font-bold mb-1 text-left mb-5">
                        Amount
                      </label>
                      <div className="flex gap-2 items-center ">
                        <input
                          type="number"
                          value={number}
                          onChange={(e) => setNumber(e.target.value)}
                          disabled={isApprove}
                          className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                        />
                        <span className="font-bold ">BNB</span>
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                    <button
                      className="text-white border-2 border-[#d9b865]  font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                      onClick={Buy}
                    >
                      {"Buy"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
      <Footer />
    </>
  );
}

const FeatureData = [
  {
    icon: "Mobile",
    path: process.env.PUBLIC_URL + "assets/images/Mobile.svg",
    title: "Mobile Application",
    text: "BEEPBOP will be available on iOS App Store and Google Play Store (Restricted to certain Country)",
  },
  {
    icon: "Earn",
    path: process.env.PUBLIC_URL + "assets/images/Earn.svg",
    title: "Post-to-Earn",
    text: "Users can post Photos or Videos and earn from the number of views, likes and share. User can burn token to boost post, level up and customize account",
  },
  {
    icon: "Share",
    title: "Pay-to-Share",
    path: process.env.PUBLIC_URL + "assets/images/Share.svg",
    text: "Users can sell original photo/video content in marketplace",
  },
  {
    icon: "Post-to-Earn",
    path: process.env.PUBLIC_URL + "assets/images/Metamask.svg",
    title: "Metamask Integration",
    text: "Users can deposit and send Cryptocurrencies to the wallet to fund their spending accounts",
  },
];

const SocialLinksData = [
  {
    link: "/",
    name: "Discord",
    path: process.env.PUBLIC_URL + "assets/images/discord.svg",
  },
  {
    link: "/",
    name: "Twitter",
    path: process.env.PUBLIC_URL + "assets/images/twitter.svg",
  },
  {
    link: "/",
    name: "Telegram",
    path: process.env.PUBLIC_URL + "assets/images/telegram.svg",
  },
  {
    link: "/",
    name: "Facebook",
    path: process.env.PUBLIC_URL + "assets/images/facebook.svg",
  },
];

const ProjectHighLight = [
  {
    title: "Crypto Experiene",
    text: "BEEPBOP builds a full ecosystem vertical including wallet integration, swap and marketplace so, that the app is intuitive to use.",
  },
  {
    title: "Onboarding non-crypto users",
    text: "Pre-account unable non-cryto user to try for free and once they have enough tokens earned from the referral system, they can buy regular to premium account and start their crypto journey.",
  },
  {
    title: "Friendly to all kinds to people",
    text: "All types of accounts are designed to be user friendly, user can choose regular to premiumaccounts to have more engagements or audience.",
  },
  {
    title: "Available on iOS/Android:",
    text: "Users can download BEEPBOP from iOS AppStore and Google Play Store.",
  },
];

const Products = [
  {
    title: "BEEPBOP Account (Post-to-Earn):",
    text: "User can start posting video content and start earning reward tokens.",
  },
  {
    title: "Burning reward tokens:",
    text: "User can pay reward tokens to other user if they want other user to share their video content and to have more engagements/ views. If user #1likes the video content of user #2, user #2 will earn rewards tokens deducted from the wallet of user #1.",
  },
  {
    title: "Boosting and Ads:",
    text: "Users can boost their video content to BEEPBOP App to get more engagement/views. For businesses, BEEPBOP offers paid Ads and get a crypto oriented audience.",
  },
  {
    title: "NFT Marketplace:",
    text: "User can sell their video content on marketplace.",
  },
];

const Section2Data = [
  {
    title: "GOVERNANCE:",
    text: "Volume amount of PTT can participate the governance and have avoting power to all Project.",
  },
  {
    title: "STAKING REWARD:",
    text: "PTT holder can stake their token and earn rewards.",
  },
  {
    title: "ACCRUAL OF PROTOCOL REVENUE:",
    text: "PPT is burned to access in -app features, such as opening a account, like post, share post, account upgrade, follower upgrade, boosting and etc.",
  },
];

const mapStateToProps = (state) => {
  return {
    metamaskAddress: state.ConnectWallet.address,
    metamaskConnect: state.ConnectWallet.isConnect,
  };
};
export default connect(mapStateToProps, null)(HomePage);
